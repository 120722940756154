import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { Col, Grid, Space, Typography } from 'antd';

import theme from 'styles/theme';
import {
  FooterContainer,
  Wrapper,
  Border,
  LinksRow,
  FooterLink,
  BuildVersion,
} from './styles';

import pIcon from 'assets/images/home/shuttle-icon.png';

function Footer() {
  const { data: session } = useSession();
  const screens = Grid.useBreakpoint();

  const isMobile = !screens.md;

  return (
    <FooterContainer $isMobile={isMobile}>
      <Wrapper $isMobile={isMobile}>
        <LinksRow justify="start">
          <Col
            flex={screens.md ? '34%' : '100%'}
            style={{
              marginBottom: screens.md ? 0 : 30,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Image src={pIcon} alt="" width={40} />
            <BuildVersion>{process.env.GITHUB_REF_NAME}</BuildVersion>
          </Col>
          <Col
            flex={screens.md ? '22%' : '50%'}
            style={{ marginBottom: screens.md ? 0 : 30 }}
          >
            <Typography.Title
              level={5}
              style={{ color: theme.colors.primaryDeep }}
            >
              General
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <Link href="/help/code-of-conduct" passHref legacyBehavior>
                <FooterLink type="secondary">Code of Conduct</FooterLink>
              </Link>
              {/* <Link href="/help/faq?activeTab=investor" passHref legacyBehavior>
                <FooterLink type="secondary">Investor FAQ</FooterLink>
              </Link>
              <Link href="/help/faq?activeTab=founder" passHref legacyBehavior>
                <FooterLink type="secondary">Founder FAQ</FooterLink>
              </Link> */}
              <FooterLink
                href="mailto:support@shuttle.club"
                target="_blank"
                rel="noreferrer"
                type="secondary"
              >
                Support
              </FooterLink>
              {session?.user && (
                <Link href="/auth/data-removal" passHref legacyBehavior>
                  <Typography.Text type="secondary">
                    Data Removal
                  </Typography.Text>
                </Link>
              )}
            </Space>
          </Col>
          <Col
            flex={screens.md ? '22%' : '50%'}
            style={{ marginBottom: screens.md ? 0 : 30 }}
          >
            <Typography.Title
              level={5}
              style={{ color: theme.colors.primaryDeep }}
            >
              Legal
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <Link href="/legal/risk-warning" passHref legacyBehavior>
                <FooterLink type="secondary">Risk Warning</FooterLink>
              </Link>
              {/* <Link href="/legal/investee-terms" passHref legacyBehavior>
                <FooterLink type="secondary">Investee Terms</FooterLink>
              </Link>
              <Link href="/legal/terms-of-use" passHref legacyBehavior>
                <FooterLink type="secondary">Terms of Use</FooterLink>
              </Link> */}
              <Link href="/legal/privacy-policy" passHref legacyBehavior>
                <FooterLink type="secondary">Privacy Policy</FooterLink>
              </Link>
              <Link href="/legal/complaints" passHref legacyBehavior>
                <FooterLink type="secondary">Complaints</FooterLink>
              </Link>
            </Space>
          </Col>
          <Col flex={screens.md ? '22%' : '100%'} style={{ marginBottom: 0 }}>
            <Typography.Title
              level={5}
              style={{ color: theme.colors.primaryDeep }}
            >
              Social
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <FooterLink
                href="https://www.linkedin.com/company/shuttleclub"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/club.shuttle"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </FooterLink>
              <FooterLink
                href="https://x.com/club_shuttle"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </FooterLink>
              <FooterLink
                href="https://www.youtube.com/@shuttle-club"
                target="_blank"
                rel="noreferrer"
              >
                Youtube
              </FooterLink>
            </Space>
          </Col>
        </LinksRow>
      </Wrapper>
      <Border />
      <Wrapper $isMobile={isMobile}>
        <LinksRow>
          <Typography.Title
            level={5}
            style={{ color: theme.colors.primaryDeep }}
          >
            Risk Warning
          </Typography.Title>
          <Typography.Paragraph
            style={{
              fontSize: 14,
              lineHeight: '22px',
              color: theme.colors.textSecondary,
            }}
          >
            PitchedIt Ltd t/a Shuttle is regulated by the Central Bank of
            Ireland, registered with the Companies Registration Office in
            Ireland, and company number 697037. All investments entail risks,
            including the risk of partial or entire loss of the money invested.
            Your investment is not covered by a deposit guarantee scheme or by
            an investor compensation scheme.
          </Typography.Paragraph>
        </LinksRow>
      </Wrapper>
    </FooterContainer>
  );
}

export default Footer;
